import { ReactTooltip } from 'components/shared/Tooltip'
import { useEffect, useRef, useState } from 'react'
import { twJoin } from 'tailwind-merge'
export const TruncatedText = ({ content }) => {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(true)
  const textRef = useRef(null)

  useEffect(() => {
    const element = textRef.current
    if (element) {
      // Detect if text should be truncated
      if (element.scrollWidth > element.clientWidth) {
        setIsVisibleTooltip(true)
      } else {
        setIsVisibleTooltip(false)
      }
    }
  }, [content])

  return (
    <ReactTooltip enabled={isVisibleTooltip} tooltipContent={content}>
      <div
        ref={textRef}
        className={twJoin('max-w-40 overflow-hidden whitespace-nowrap', isVisibleTooltip && 'truncate')}
      >
        {content}
      </div>
    </ReactTooltip>
  )
}
