import { setMessage } from 'app/features/smartChatSlice'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { twMerge } from 'tailwind-merge'

const NEW_LINE = '\n'

export const TextareaWithButton = ({ handleOnClick = () => {} }) => {
  const dispatch = useDispatch()
  const { isLoading, message } = useSelector(({ smartChat }) => smartChat)

  const textAreaRef = useRef(null)
  const isDisabledButton = isLoading || message === ''

  useEffect(() => {
    const textArea = textAreaRef.current
    if (textArea) {
      textArea.style.height = 'auto'
      textArea.style.height = `${textArea.scrollHeight}px`
    }
  }, [message])

  return (
    <div className="mx-13 flex w-4/5 items-center justify-center rounded-lg border shadow-md">
      <div className="container">
        <textarea
          disabled={isLoading}
          ref={textAreaRef}
          className={`centered-input small w-full focus:outline-none active:no-underline ${
            !message?.trim() || message?.split(NEW_LINE)?.length === 1 ? 'center' : ''
          } ${isLoading ? 'text-gray-400' : ''}`}
          value={message}
          onChange={(e) => {
            e.preventDefault()
            dispatch(setMessage(e.target.value))
          }}
          placeholder="How can I help you?"
          rows={1}
          style={{
            maxHeight: '70px'
          }}
        />
      </div>
      <div className="flex">
        <button
          disabled={isDisabledButton}
          onClick={() => {
            handleOnClick()
          }}
        >
          <div
            className={twMerge(
              'align-center my-2 mr-2 flex justify-center rounded-md px-5 py-2',
              isDisabledButton
                ? 'bg-gray-100 text-gray-400'
                : 'bg-gradient-to-r from-purple-500 to-purple-700 text-white'
            )}
          >
            <img
              className="mr-1 h-4 w-4"
              src={`${isDisabledButton ? '/icons/grayStar' : '/icons/whiteStar'}.png`}
              alt="Generate"
            />
            <p className="big ml-1">{isLoading ? 'Processing...' : 'Generate'}</p>
          </div>
        </button>
      </div>
    </div>
  )
}
