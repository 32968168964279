import { TextareaWithButton } from 'components/shared/Inputs/TextareaWithButton'
import { DefaultModal } from 'components/shared/Modal/DefaultModal'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export const FooterActions = ({ onSubmit }) => {
  const { selectedFiles } = useSelector(({ files }) => files)
  const [isShowedNoFileModal, setIsShowedNoFileModal] = useState(false)

  const handleOnSubmitTextarea = () => {
    if (selectedFiles.length === 0) {
      setIsShowedNoFileModal(true)
      return
    }
    onSubmit()
  }

  const handleOnSubmitModal = () => {
    setIsShowedNoFileModal(false)
    onSubmit()
  }

  return (
    <div className="w-full pt-4">
      <div className="mx-1 flex flex-col items-center px-3 pb-2">
        <TextareaWithButton handleOnClick={() => handleOnSubmitTextarea()} />
      </div>

      <DefaultModal
        isVisible={isShowedNoFileModal}
        onClose={() => setIsShowedNoFileModal(false)}
        onSubmit={() => handleOnSubmitModal()}
        title="Generate answer"
        size="s"
      >
        <span>No file selected. Do you want to continue?</span>
      </DefaultModal>
    </div>
  )
}
