
export function Pagination({ limit, offset, count, setOffset, loading }) {
    if (!limit || !count) return

    const pages = Math.ceil(count / limit)
    const currentPage = Math.ceil(offset / limit) + 1

    const handlePageChange = (e) => {
        if (loading) return
        const page = parseInt(e.target.innerText)
        setOffset((page - 1) * limit)
    }

    const handlePrevPage = () => {
        if (loading) return
        if (offset - limit >= 0) {
            setOffset(offset - limit)
        }
    }

    const handleNextPage = () => {
        if (loading) return
        if (offset + limit < count) {
            setOffset(offset + limit)
        }
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
                <li>
                    <button className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 "
                        onClick={handlePrevPage}
                    >
                        Previous
                    </button>
                </li>
                {Array.from({ length: pages }, (_, index) => {
                    return (
                        <li key={index}>
                            <button className={`flex items-center justify-center px-3 h-8 leading-tight border border-gray-300 
                                    ${currentPage === index + 1 ?
                                    "text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700" :
                                    "text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700"}`}
                                onClick={handlePageChange}
                            >
                                {index + 1}
                            </button>
                        </li>
                    )
                })}
                <li>
                    <button className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
                        onClick={handleNextPage}
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    )
}
