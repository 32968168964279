import { Form } from './components/Form'
import { OutputSection } from './components/OutputSection'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { cleanSelectedFiles } from 'app/features/fileSlice'
import { getLoogConfig } from 'api/loop/handler'
import { setConfig } from 'app/features/loopSlice'
import { toast } from 'react-toastify'

export const MainContent = () => {
  const dispatch = useDispatch()
  const { outputData } = useSelector((state) => state.loop)

  useEffect(() => {
    dispatch(getLoogConfig()).then((response) => {
      if (response.status !== 200) {
        toast.error('Something went wrong')
      } else {
        dispatch(setConfig(response.data))
      }
    })
    dispatch(cleanSelectedFiles())
  }, [dispatch])

  return (
    <div className="flex h-full w-full shrink grow basis-0 justify-center overflow-y-auto">
      <div className="h-full w-3/4">{Object.keys(outputData).length === 0 ? <Form /> : <OutputSection />}</div>
    </div>
  )
}
