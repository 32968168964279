import { setShowMessageLoop } from 'app/features/fileSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getDocuments, handleFileUpload } from 'api/Document/action/document'
import { setFile, toggleFileSelection } from 'app/features/fileSlice'
import { UploadModal } from 'components/shared/Modal/UploadModal'
// import { DefaultModal } from 'components/shared/Modal/DefaultModal' // If needed for confirmations
import { toast } from 'react-toastify' // Optional: for notifications

export const Contents = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { showMessageMirror, showMessageLoop, selectedFiles, files, qntyFiles } = useSelector(({ files }) => files)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetchDocuments = async () => {
    try {
      const response = await dispatch(getDocuments())
      if (response.status === 200) {
        const documents = response.data.results
        dispatch(setFile(documents))
      }
    } catch (error) {
      console.error('Error fetching documents:', error)
      toast.error('Failed to fetch documents. Please try again.') // Optional
    }
  }

  useEffect(() => {
    fetchDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (qntyFiles !== 1) {
      dispatch(setShowMessageLoop(true))
    } else {
      dispatch(setShowMessageLoop(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qntyFiles])

  const handleClick = () => {
    setShowModal(true)
  }

  const handleFileSelection = (index) => {
    dispatch(toggleFileSelection(index))
  }

  /**
   * Callback function to handle upload events from UploadModal
   */
  const handleUploadEvent = (event, data) => {
    if (event === 'processing_complete') {
      fetchDocuments()
      setShowModal(false)
    }
    // Handle other events if necessary
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <img src="/icons/down.png" alt="logo" className="h-4 w-4 object-cover" />
          <p className="big">Contents</p>
        </div>

        <div className="flex justify-end">
          <button htmlFor="fileInput" onClick={handleClick} className="rounded-md px-2 text-xs hover:bg-blue-100">
            <div className="flex items-center gap-1">
              <img className="size-4" src="/icons/add-circle.png" alt="add" />
              <span>Add</span>
            </div>
          </button>
        </div>
      </div>
      <div className="mb-6 mt-2 shrink grow basis-0 overflow-y-auto">
        <div className="flex flex-col pb-2">
          {files?.map((val, index) => (
            <button
              key={index}
              className={`my-1 mr-2 flex rounded p-2 text-sm ${
                selectedFiles.includes(index) ? 'bg-[#E6EDFF]' : 'bg-[#FBFBFB] hover:bg-[#E6EDFF]'
              }`}
              onClick={() => handleFileSelection(index)}
            >
              <div className="small overflow-auto text-[14px] text-[#383838] no-underline hover:no-underline">
                {val?.title}
              </div>
            </button>
          ))}
        </div>
      </div>

      {showMessageLoop && pathname === '/playground/loop' && (
        <div className="small mb-4 rounded bg-yellow-200 px-4 py-2">Please select one file</div>
      )}

      {showMessageMirror && pathname === '/playground/mirror' && (
        <div className="small mb-4 rounded bg-yellow-200 px-4 py-2">
          Please upload two PDF files and select or enter at least one question.
        </div>
      )}

      <UploadModal
        handleFileUpload={handleFileUpload}
        setShowModal={setShowModal}
        isLoading={isLoading}
        isVisible={showModal}
        onUploadEvent={handleUploadEvent} // Pass the callback function
      />

      {/* Optional: Confirmation Modal for Deletions */}
      {/* 
      <DefaultModal
        size="s"
        isVisible={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onSubmit={confirmDelete}
        title="Delete File"
      >
        <span>Are you sure you want to delete this file?</span>
      </DefaultModal>
      */}
    </>
  )
}

export default Contents
