import { Spinner } from './Spinner'

export const DarkSpinner = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <Spinner />
      <h1 className="text-lg font-bold">Processing Data</h1>
      <h4 className="text-gray-400">Please be patient as we are generating the data.</h4>
      <h4 className="text-gray-400">This may take some time</h4>
    </div>
  )
}
