import { useCopyText } from 'hooks/useCopyText'

export const MessageActions = ({ expandedAction, copyContent }) => {
  const { copyToClipboard } = useCopyText()

  return (
    <div className="flex shrink-0 items-center gap-1">
      <button className="m-1" onClick={() => copyToClipboard(copyContent)}>
        <img className="h-5 w-5" src="/icons/copy.svg" alt="copy" />
      </button>
      <button className="m-1" onClick={() => expandedAction.setIsExpanded(!expandedAction.isExpanded)}>
        <img className="h-4" src={`${expandedAction.isExpanded ? '/icons/down.png' : '/icons/up.png'}`} alt="toggle" />
      </button>
    </div>
  )
}
