import { GenerateButton } from 'components/shared/Buttons'
import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { useSelector } from 'react-redux'

export const FooterButtons = ({ isTaskMode = false }) => {
  const { qntyFiles } = useSelector(({ files }) => files)

  return (
    <>
      {!isTaskMode && (
        <div className="flex justify-end">
          <ReactTooltip
            enabled={qntyFiles !== 1}
            tooltipContent={
              qntyFiles === 0 ? 'You must select a file from the sidebar content list' : 'You must select only one file'
            }
          >
            <GenerateButton isDisabled={qntyFiles !== 1} />
          </ReactTooltip>
        </div>
      )}
    </>
  )
}
