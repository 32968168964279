import { postLoop } from 'api/loop/handler'
import { addData, addOutputData, setIsLoading, setPayload } from 'app/features/loopSlice'
import { removeSelectedPresets } from 'app/features/loopTasksSlice'
import { Card } from 'components/shared/Card/Card'
import { DarkSpinner } from 'components/shared/Loaders'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { LoopFormFields } from './LoopFormFields/LoopFormFields'

export const Form = () => {
  const dispatch = useDispatch()
  const { files, selectedFiles } = useSelector(({ files }) => files)
  const { isLoading } = useSelector(({ loop }) => loop)

  const onSubmit = async (data) => {
    dispatch(setPayload(data))
    dispatch(setIsLoading(true))
    dispatch(removeSelectedPresets())

    const response = await dispatch(postLoop({ ...data, document: files[selectedFiles] }))

    if (response.status !== 201) {
      toast.error('Something went wrong')
    } else {
      dispatch(addData(response.data))
      const token = JSON.parse(localStorage.getItem('access_token'))
      const wsScheme = process.env.REACT_APP_FORCE_WSS ? 'wss' : (window.location.protocol === 'https:' ? 'wss' : 'ws')
      const wsPath = `/ws/${response.data.id}/process`
      const wsUrl = `${wsScheme}://${process.env.REACT_APP_WS_URL}${wsPath}?access_token=${token}`
      const socket = new WebSocket(wsUrl)

      const data = JSON.stringify({ pk: response.data.id })
      socket.onopen = () => {
        socket.send(data)
      }
      socket.onmessage = (event) => {
        const responseProcessedLoop = JSON.parse(event.data)
        if (responseProcessedLoop.result) {
          dispatch(addOutputData(responseProcessedLoop))
          toast.success('File processed successfully')
          dispatch(setIsLoading(false))
          socket.close()
        } else {
          toast.error('Something went wrong during processing')
        }
      }
      socket.onerror = () => {
        toast.error('WebSocket connection error')
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <DarkSpinner />
      ) : (
        <div className="pb-5">
          <Card>
            <LoopFormFields onSubmit={onSubmit} />
          </Card>
        </div>
      )}
    </>
  )
}
