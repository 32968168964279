import { addThreadDocument, askQuestion, createThread, removeThreadDocument, handleSendMessage } from 'api/SmartAssistant/action/action'
import { addData, setThreadId, setIsLoading, setMessage } from 'app/features/smartChatSlice'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FooterActions } from './FooterActions'
import { Messages } from './Messages'
import { removeSelectedPresets, toggleOffPresetSelection } from 'app/features/smartChatTasksSlice'

export const SmartChat = () => {
  const dispatch = useDispatch()
  const { pdfStatus, data, isLoading, message } = useSelector((state) => state.smartChat)
  const { selectedFiles, files } = useSelector((state) => state.files)
  const { presets } = useSelector((state) => state.smartChatTasks)

  const [isFirstPost, setIsFirstPost] = useState(true)
  const [threadIdLocal, setThreadIdLocal] = useState(null)
  const [selectedFilesCopy, setSelectedFilesCopy] = useState([])

  const containerRef = useRef(null)

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    if (containerRef.current && isLoading) {
      scrollToBottom()
    }
  }, [containerRef, isLoading])

  useEffect(() => {
    scrollToBottom()
  }, [data, pdfStatus])

  useEffect(() => {
    if (threadIdLocal) {
      const filesAdded = selectedFiles.filter((index) => !selectedFilesCopy.includes(index))
      const filesRemoved = selectedFilesCopy.filter((index) => !selectedFiles.includes(index))
      if (filesAdded.length > 0) {
        filesAdded.forEach((index) =>
          dispatch(addThreadDocument(threadIdLocal, files[index].id)).then((response) => {
            if (!response.status || response.status > 300) {
              toast.error(response.data)
              return
            }
            setSelectedFilesCopy(selectedFiles)
          })
        )
      } else if (filesRemoved.length > 0) {
        filesRemoved.forEach((index) => {
          dispatch(removeThreadDocument(threadIdLocal, files[index].id)).then((response) => {
            if (!response.status || response.status > 300) {
              toast.error(response.data)
              return
            }
            setSelectedFilesCopy(selectedFiles)
          })
        })
      }
    }
  }, [selectedFiles])

  useEffect(() => {
    const selectedPreset = presets.find((preset) => preset.selected)
    if (selectedPreset) {
      dispatch(setMessage(selectedPreset.data))
      dispatch(toggleOffPresetSelection(selectedPreset.id))
    }
  }, [presets])

  useEffect(() => {
    if (!isLoading) {
      dispatch(setMessage(''))
    }
  }, [])

  const postData = async () => {
    let localThreadId = threadIdLocal
  
    const selectedFilesToUpload = selectedFiles.map((index) => files[index])
    if (isFirstPost) {
      const documentIds = selectedFilesToUpload.map((file) => file.id)
      const response = await dispatch(createThread(documentIds))
  
      if (!response.status || response.status > 201) {
        toast.error(response.data)
        return
      }
      setThreadIdLocal(response.data.id)
      dispatch(setThreadId(response.data.id))
      localThreadId = response.data.id
      setIsFirstPost(false)
    }
  
    if (localThreadId == null) return
  
    const newResponse = await new Promise((resolve, reject) => {
      handleSendMessage(
        localThreadId,
        message,
        selectedFilesToUpload.map((file) => file.id),
        (action, data) => {
          if (action === 'response') {
            resolve(data)
          } else if (action === 'processing_error' || action === 'error') {
            toast.error("There was an error processing your request")
            reject(data)
          }
        }
      )
    }).catch((error) => {
      console.error('Error:', error)
      return
    })
  

    if (newResponse) {
      console.log("newResponse", newResponse)
      dispatch(addData({ question: message, answer: newResponse }))
      dispatch(setMessage(''))
      dispatch(removeSelectedPresets())
    }
  }

  const onSubmitGenerate = async () => {
    dispatch(setIsLoading(true))
    await postData()
    dispatch(setIsLoading(false))
    scrollToBottom()
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <Messages ref={containerRef} />
      <FooterActions onSubmit={onSubmitGenerate} />
    </div>
  )
}
