import { twJoin } from 'tailwind-merge'

export const ModalFooterButtons = ({ onClose, onSubmit, isLoading, isDisabled, formId }) => {
  const handleSubmitButton = () => {
    const form = document.getElementById(formId)
    if (form) {
      //Intercept any form submit in the content of modal if formId is given to form
      form.requestSubmit()
    } else {
      onSubmit()
    }
  }

  return (
    <div className="mt-2 flex items-center justify-end px-4 pb-3">
      {onClose && (
        <button
          onClick={() => onClose()}
          className={twJoin(
            'big m-2 rounded border px-5 py-1 font-semibold hover:bg-blue-100',
            isLoading && 'cursor-not-allowed opacity-50 hover:bg-transparent'
          )}
          disabled={isLoading}
        >
          Cancel
        </button>
      )}
      {onSubmit && (
        <button
          onClick={() => handleSubmitButton()}
          className={twJoin(
            'big m-2 rounded bg-blue-600 px-5 py-1  font-semibold text-white hover:bg-blue-500',
            isDisabled ? 'cursor-not-allowed opacity-50 hover:bg-blue-600' : 'cursor-pointer'
          )}
          disabled={isDisabled}
        >
          Confirm
        </button>
      )}
    </div>
  )
}
