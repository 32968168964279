import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  presets: [],
  selectedTask: {}
}

const loopTasksSlice = createSlice({
  name: 'loopTasks',
  initialState,
  reducers: {
    addPreset(state, action) {
      // state.presets.push(action.payload);
      state.presets = action.payload
    },
    deletePreset(state, action) {
      state.presets.splice(action.payload, 1)
    },
    togglePresetSelection(state, action) {
      const index = action.payload
      // Deselect all presets
      state.presets.forEach((preset, idx) => {
        if (idx !== index) {
          preset.selected = false
        }
      })

      //Add as selected task if it's selected
      if (!state.presets[index].selected === true) {
        state.selectedTask = state.presets[index]
      } else {
        state.selectedTask = {}
      }
      // Toggle the selected preset
      state.presets[index].selected = !state.presets[index].selected
    },
    toggleOffPresetSelection(state, action) {
      const id = action.payload
      const preset = state.presets.find((p) => p.id === id)
      if (preset) {
        preset.selected = false
        state.selectedTask = {}
      } else {
        console.error('Preset not found')
      }
    },
    editPresetText(state, action) {
      const { index, text } = action.payload
      state.presets[index].textField = text
    },
    removeSelectedPresets(state, action) {
      state.presets.forEach((preset) => {
        preset.selected = false
        state.selectedTask = {}
      })
    },
    selectedTask(state, action) {
      state.task = action.payload
    },
    resetLoopTasks: () => initialState
  }
})

export const {
  addPreset,
  deletePreset,
  togglePresetSelection,
  editPresetText,
  removeSelectedPresets,
  resetLoopTasks,
  toggleOffPresetSelection,
  selectedTask
} = loopTasksSlice.actions
export default loopTasksSlice.reducer
