import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Answer } from './Answer'
import { DarkSpinner, Spinner } from 'components/shared/Loaders'

export const AnswerList = () => {
  const { data, isLoading } = useSelector((state) => state.mirror)
  const [expandedItems, setExpandedItems] = useState({})
  const containerRef = useRef(null)

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    if (containerRef.current && isLoading) {
      scrollToBottom()
    }
  }, [containerRef, isLoading])

  useEffect(() => {
    if (containerRef.current) {
      scrollToBottom()
    }
  }, [data.length])

  const toggleExpand = (answerId, isExpanded) => {
    setExpandedItems((prev) => ({ ...prev, [answerId]: isExpanded }))
  }

  return (
    <>
      {isLoading && data.length === 0 && <DarkSpinner />}

      <div ref={containerRef} className="grow-0 overflow-y-auto text-[#5F5978]">
        {data.length !== 0 && (
          <>
            {data.map((answer, i) => (
              <div key={i} className="mb-2 w-full px-5">
                <div className="mb-4 rounded-lg bg-violet-100 px-4 py-2 shadow-md">
                  <Answer content={answer} isExpanded={expandedItems[answer.id]} toggleExpand={toggleExpand} />
                </div>
              </div>
            ))}

            {isLoading && data.length !== 0 && (
              <div className="mx-auto mb-2 w-10">
                <Spinner />
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
