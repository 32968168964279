import * as api from '../index.js'
export const addTask = (title, instruction, configurations, workspace) => async (dispatch) => {
  try {
    const data = {
      workspace: workspace,
      title: title,
      data: instruction,
      configurations
    }

    const response = await api.addTask(data)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const getTaskList = () => async (dispatch) => {
  try {
    const response = await api.getTaskList()
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const deleteTask = (id) => async (dispatch) => {
  try {
    const response = await api.deleteTask(id)
    if (response.status === 204) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
