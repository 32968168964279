import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  presets: [
    { input: 'Default Prompt 1', textField: 'Default Description 1', selected: false },
    { input: 'Default Prompt 2', textField: 'Default Description 2', selected: false }
  ]
}

const mirrorTasksSlice = createSlice({
  name: 'mirrorTasks',
  initialState,
  reducers: {
    addPreset(state, action) {
      // state.presets.push(action.payload);
      state.presets = action.payload
    },
    deletePreset(state, action) {
      state.presets.splice(action.payload, 1)
    },
    togglePresetSelection(state, action) {
      const index = action.payload
      state.presets[index].selected = !state.presets[index].selected
    },
    toggleOffPresetSelection(state, action) {
      const id = action.payload
      const preset = state.presets.find((p) => p.id === id)
      if (preset) {
        preset.selected = false
      } else {
        console.error('Preset not found')
      }
    },
    editPresetText(state, action) {
      const { index, text } = action.payload
      state.presets[index].textField = text
    },
    removeSelectedPresets(state) {
      state.presets.forEach((preset) => {
        preset.selected = false
      })
    },
    resetMirrorTasks: () => initialState
  }
})

export const {
  addPreset,
  deletePreset,
  togglePresetSelection,
  editPresetText,
  toggleOffPresetSelection,
  removeSelectedPresets,
  resetMirrorTasks
} = mirrorTasksSlice.actions
export default mirrorTasksSlice.reducer
