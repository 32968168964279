export function getUrlParams(url) {
    const query = url.split('?')[1];
    return query
        ? query.split('&').reduce((acc, param) => {
            const [key, value] = param.split('=');
            acc[key] = value;
            return acc;
        }, {})
        : {};
}

export function urlParamsToString(params) {
    if (!params) return '';
    if (Object.keys(params).length === 0) return '';
    return `?${Object.keys(params).reduce((acc, key) => {
        acc.push(`${key}=${params[key]}`);
        return acc;
    }, []).join('&')}`;
}