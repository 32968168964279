import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  payload: {},
  outputData: {},
  isLoading: false,
  pdfStatus: null,
  config: {}
}

const loopSlice = createSlice({
  name: 'loop',
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload
    },
    setPayload: (state, action) => {
      state.payload = action.payload
    },
    addData: (state, action) => {
      state.data = action.payload
    },
    addOutputData: (state, action) => {
      state.outputData = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setPdfStatus: (state, action) => {
      state.pdfStatus = action.payload
    },
    resetLoop: (state) => {
      return { 
        ...initialState, 
        config: state.config
      }
    }
  }
})

export const { setConfig, setPayload, addData, addOutputData, setIsLoading, setPdfStatus, resetLoop } = loopSlice.actions
export default loopSlice.reducer
