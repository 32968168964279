import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LoopTutorialModal } from './components/LoopTutorialModal'
import { MirrorTutorialModal } from './components/MirrorTutorialModal'
import { AssistantTutorialModal } from './components/AssistantTutorialModal'
import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'

export const TutorialModal = () => {
  const { pathname } = useLocation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <ReactTooltip tooltipContent="Tutorial" className="flex">
        <button onClick={() => setShowModal(true)}>
          <img className="size-6" src="/icons/message-text.png" alt="reset" />
        </button>
      </ReactTooltip>

      <AssistantTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/assistant')} />
      <LoopTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/loop')} />
      <MirrorTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/mirror')} />
    </>
  )
}
