import { Select, Textarea } from 'components/shared/Inputs'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FooterButtons } from './components/FooterButtons'

export const LoopFormFields = ({ onSubmit, formId, isTaskMode }) => {
  const { register, handleSubmit } = useForm()
  const { config } = useSelector(({ loop }) => loop)
  const { selectedTask } = useSelector(({ loopTasks }) => loopTasks)

  const selectDefaultValues = selectedTask && selectedTask.configurations
  const textareaDefaultValue = selectedTask && selectedTask.data

  const FORM_SELECT_INPUTS = {
    goal: {
      label: 'Goal',
      iconSrc: '/icons/question.png',
      iconInfoTooltip: 'The overall purpose for processing',
      options: config?.goal?.map((item) => ({ label: item.value, value: item.key }))
    },
    length: {
      label: 'Length',
      iconSrc: '/icons/question.png',
      iconInfoTooltip:
        'The approximate length of the result. It may vary depending on the length of the original document.',
      options: config?.length?.map((item) => ({ label: item.value, value: item.key }))
    },
    format: {
      label: 'Format',
      iconSrc: '/icons/question.png',
      iconInfoTooltip: 'The format in which the results are presented.',
      options: config?.result_format?.map((item) => ({ label: item.value, value: item.key }))
    },
    language: {
      label: 'Language',
      iconSrc: '/icons/question.png',
      iconInfoTooltip: 'The language in which the results are presented.',
      options: config?.language?.map((item) => ({ label: item.value, value: item.key }))
    }
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="flex flex-col gap-8 text-sm">
        <div className="flex grow flex-col gap-5">
          {Object.entries(FORM_SELECT_INPUTS).map(([key, value]) => {
            return (
              <Select
                key={key}
                {...value}
                register={{ ...register(key) }}
                defaultValue={selectDefaultValues && selectDefaultValues[key]}
              />
            )
          })}
          <Textarea
            label="Attention"
            iconSrc="/icons/question.png"
            iconInfoTooltip="This field is used to specify to the AI what it has to pay special attention to when processing the document and generating the results."
            register={register('attention')}
            defaultValue={textareaDefaultValue}
            className="max-h-24"
          />
        </div>

        <FooterButtons isTaskMode={isTaskMode} onSubmit={onSubmit} />
      </div>
    </form>
  )
}
