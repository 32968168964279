import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profile: {},
  isAuthorized: null
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload
    }
  }
})

export const { setProfile, setIsAuthorized } = loginSlice.actions
export default loginSlice.reducer
