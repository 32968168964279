import { MarkdownWrapper } from 'components/shared/MarkdownWrapper/MarkdownWrapper'
import { useCopyText } from 'hooks/useCopyText'

export const Answer = ({ content, toggleExpand, isExpanded = false }) => {
  const { copyToClipboard } = useCopyText()

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <h1 className="text-pretty text-[14px] font-semibold text-[#5F5978]">{content.data}</h1>
        </div>
        <div className="flex items-center gap-1">
          <button className="m-1" onClick={() => copyToClipboard(content.response)}>
            <img className="h-5 w-5" src="/icons/copy.svg" alt="copy" />
          </button>
          <button className="m-1" onClick={() => toggleExpand(content.id, !isExpanded)}>
            <img
              key={content.id}
              className="h-4"
              src={`${isExpanded ? '/icons/down.png' : '/icons/up.png'}`}
              alt="toggle"
            />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div key={content.id} className="small">
          <div className="pb-2 text-[13px]">
            <MarkdownWrapper content={content.response} />
          </div>
        </div>
      )}
    </>
  )
}
