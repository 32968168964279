import { setIsOpenSidebar } from 'app/features/navigationSlice'
import { DASHBOARD_SIDEBAR_LINKS } from 'lib/constants'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { SidebarLink } from './components/SidebarLink'

export const Sidebar = () => {
  const dispatch = useDispatch()
  const { isOpenSidebar } = useSelector(({ navigation }) => navigation)

  return (
    <div
      onMouseEnter={() => dispatch(setIsOpenSidebar(true))}
      onMouseLeave={() => dispatch(setIsOpenSidebar(false))}
      className={twMerge(
        'flex shrink-0 flex-col bg-white px-2 py-4',
        'w-14 transition-all duration-300 ease-in-out hover:w-60'
      )}
    >
      <Link to="/">
        <div className="m-1 flex items-center gap-2 px-1 py-1">
          <img className="mr-1 h-12 shrink-0" src="/icons/logo.png" alt="silmaril" />
          {isOpenSidebar && (
            <div className="mb-2 mt-auto">
              <img className="h-5" src="/icons/silmaril.png" alt="silmaril" />
            </div>
          )}
        </div>
      </Link>
      <div className="flex flex-1 flex-col gap-1 py-4">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} droplist={link.dropable} />
        ))}
      </div>
    </div>
  )
}
