import { IAMessageCard, Card } from 'components/shared/Card'
import { useSelector } from 'react-redux'

export const OutputSection = () => {
  const { outputData, payload } = useSelector(({ loop }) => loop)

  return (
    <div className="flex flex-col gap-5 pb-5">
      <Card>
        <h2 className="mb-1 text-lg font-semibold text-gray-400">Parameters</h2>
        <ul className="flex flex-col gap-1 text-sm">
          {Object.entries(payload).map(
            ([key, value]) =>
              value && (
                <li key={key} className="flex items-center gap-1 capitalize">
                  <span className="font-bold">{key} -&gt;</span>
                  <span className="font-medium">{value}</span>
                </li>
              )
          )}
        </ul>
      </Card>
      <IAMessageCard content={outputData.result} />
    </div>
  )
}
