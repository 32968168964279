// Files.jsx

import { deleteDocument, getDocuments, handleFileUpload } from 'api/Document/action/document'
import { Header } from 'components/shared/Headers/Header'
import { DefaultModal } from 'components/shared/Modal/DefaultModal'
import { UploadModal } from 'components/shared/Modal/UploadModal'
import { useDownloadFile } from 'hooks/useDownloadFile'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const Files = () => {
  const dispatch = useDispatch()
  const { downloadFile } = useDownloadFile()
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchDocuments = async () => {
    const response = await dispatch(getDocuments())
    if (response && Array.isArray(response.data.results) && !response.error) {
      setFiles(response.data.results)
    } else {
      setFiles([])
    }
  }

  useEffect(() => {
    fetchDocuments()
  }, [dispatch])

  const [searchQuery, setSearchQuery] = useState('')
  const [showModal, setShowModal] = useState(false)

  const filteredFiles = files.filter((file) => file.title.toLowerCase().includes(searchQuery.toLowerCase()))

  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [fileId, setFileId] = useState(null)

  const handleDelete = (id) => {
    setIsVisibleModal(true)
    setFileId(id)
  }

  const deleteFile = () => {
    const body = {
      id: fileId
    }
    dispatch(deleteDocument(body)).then((response) => {
      if (response.status === 204 && !response.error) {
        setFiles(files.filter((file) => file.id !== fileId))
      }
    })
  }

  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return '/icons/pdf.png'
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '/icons/word.png'
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '/icons/xml.png'
      case 'text/html':
        return '/icons/html.png'
      default:
        return '/icons/not-found.png'
    }
  }

  const handleUploadEvent = (event, data) => {
    if (event === 'processing_complete') {
      fetchDocuments()
      setShowModal(false)
    }
  }

  return (
    <div>
      <Header heading="Files" icon="/icons/folder.png" />

      <div className="flex items-center justify-center p-1">
        <div className="relative flex w-full items-center">
          <input
            type="text"
            placeholder="Search by file name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-lg border-gray-300 p-2 pl-9 active:outline-none"
          />
          <img
            src="/icons/search.png"
            alt="Search Icon"
            className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform"
          />
        </div>

        <button
          onClick={() => setShowModal(true)}
          className="ml-4 flex items-center justify-center rounded bg-blue-500 px-8 py-2 text-white hover:bg-blue-600"
        >
          <img src="/icons/updown.png" alt="Upload Icon" className="mr-1 h-5 w-5" />
          <p>Upload</p>
        </button>
      </div>

      <div className="p-1 py-2">
        <div className="flex flex-wrap gap-4">
          {filteredFiles.map((file) => (
            <div key={file.id}>
              <div className="flex h-60 w-60 flex-col items-center rounded-lg border border-gray-200 bg-white p-3">
                <img src={getFileIcon(file.mime_type)} alt={`${file.type} icon`} className="mb-2 h-20 w-20" />
                <span className="wrapped-text h-24 w-full overflow-hidden pt-2 text-center text-sm">{file.title}</span>

                <div className="mt-auto flex">
                  <button
                    onClick={() => handleDelete(file.id)}
                    className="big min-w-[82px] rounded-lg border bg-white px-2 py-1 text-sm text-gray-700 shadow transition-shadow duration-300 hover:bg-red-100"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => downloadFile({ name: file.title, type: file.mime_type, id: file.id })}
                    className="big ml-2 min-w-[82px] rounded-lg border px-2 py-1 text-sm text-blue-700 shadow transition-shadow duration-300 hover:bg-blue-100"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Upload Modal with onUploadEvent prop */}
      <UploadModal
        handleFileUpload={handleFileUpload}
        setShowModal={setShowModal}
        isLoading={isLoading}
        isVisible={showModal}
        onUploadEvent={handleUploadEvent} // Pass the callback here
      />

      {/* Delete Confirmation Modal */}
      <DefaultModal
        size="s"
        isVisible={isVisibleModal}
        onClose={() => {
          setIsVisibleModal(false)
          setFileId(null)
        }}
        onSubmit={() => {
          deleteFile()
          setIsVisibleModal(false)
          setFileId(null)
        }}
        title="Delete file"
      >
        <div className="flex gap-2">
          <img className="size-10" src="/icons/warning.png" alt="warning" />
          <span>You are going to delete this file. Do you want to continue?</span>
        </div>
      </DefaultModal>
    </div>
  )
}

export default Files
