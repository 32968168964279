import { cleanSelectedFiles } from 'app/features/fileSlice'
import { CompareDocuments } from './components/CompareDocuments'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const MainContent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cleanSelectedFiles())
  }, [dispatch])

  return (
    <div className="flex h-full w-full shrink grow basis-0 flex-col justify-center overflow-y-auto">
      <CompareDocuments />
    </div>
  )
}
