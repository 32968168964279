import { twMerge } from 'tailwind-merge'

export const Tag = ({ content, className }) => {
  return (
    <span
      className={twMerge(
        'rounded-lg bg-yellow-100 px-3 py-2 text-xs font-semibold text-yellow-800 opacity-80',
        className
      )}
    >
      {content}
    </span>
  )
}
