import { Link } from 'react-router-dom'

const linkClassDrop = 'flex items-center gap-1 font-light py-1 text-gray-400  hover:no-underline'

export const SidebarLinkDrop = ({ link }) => {
  return (
    <Link to={link.path} className={linkClassDrop}>
      <span className="px-3 text-xs text-gray-400">
        <img className="h-4 w-4" src={link.icon} alt={`${link.label} logo`} />
      </span>
      <p className="text-sm text-gray-400">{link.label}</p>
    </Link>
  )
}
