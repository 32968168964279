import { API } from '../baseUrl'
import { urlParamsToString } from 'utils/helpers.js'

export const documentUpload = async (body) => await API.post(`/document/`, body)
export const getBookmarks = async (urlParams) => {
    return await API.get(`/bookmark/list/${urlParams ? urlParamsToString(urlParams) : ''}`)
}
export const deleteBookmark = async (body) => await API.delete(`/bookmark/${body.id}/`)
export const addBookmark = async (body) => await API.post(`/bookmark/`, body)
export const getSingleBookmark = async (id) => await API.get(`/bookmark/${id}/`)
