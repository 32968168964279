import { DefaultModal } from 'components/shared/Modal/DefaultModal'

export const AssistantTutorialModal = ({ setShowModal, showModal }) => {
  return (
    <DefaultModal title="Assistant Tutorial" isVisible={showModal} onClose={() => setShowModal(false)}>
      <h1 className="text-[24px] font-bold">🚀 Welcome to the AI Smart Assistant</h1>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        This application allows you to interact with your documents in a completely new and exciting way. Here, you can
        ask questions about your documents and our AI assistant will provide you with answers based on the content of
        your documents. It's like having a personal assistant for your data!
      </p>

      <h1 className="text-[22px] font-bold">📁 Selecting or Uploading Documents</h1>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To use an existing document: Select your desired document in the 'Contents' section by clicking on its name. You
        can select multiple documents simultaneously.
      </p>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To upload a new document: Click on '+Add' next to 'Contents'. A pop-up will appear where you can upload your
        file. Once selected, it will automatically appear in the 'Contents' section.
      </p>

      <h1 className="text-[22px] font-bold">💬 Interacting with the Assistant</h1>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To use an existing task: Choose one of the tasks listed under the 'Tasks' section.
      </p>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To make a new request: Directly type what you need from the assistant in the chat bar.
      </p>

      <h1 className="text-[22px] font-bold">➕ Creating and Managing Tasks</h1>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To add a new task: Click on '+Add' in the 'Tasks' section. Fill in the task details and save it for future use.
      </p>

      <h1 className="text-[22px] font-bold">📥 Downloading and Saving Information</h1>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To download the conversation: Click on 'Generate PDF' to produce a PDF file of the conversation.
      </p>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To bookmark the conversation: Click on the 'Bookmark' button to mark this specific conversation.
      </p>

      <h1 className="text-[22px] font-bold">🔄 Resetting the Conversation</h1>
      <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
        To start fresh: If you want to clear the current conversation and begin anew, click on the 'Reset' button.
      </p>
    </DefaultModal>
  )
}
