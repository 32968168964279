import { Card } from 'components/shared/Card/Card'
import { useState } from 'react'
import { twJoin } from 'tailwind-merge'
import { MarkdownWrapper } from '../MarkdownWrapper/MarkdownWrapper'
import { MessageActions } from './components/MessageActions'
import { MessageIcon } from './components/MessageIcon'

export const IAMessageCard = ({ content = '', documentReferences = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <Card className="border-0 bg-violet-100 p-3">
      <div className="flex">
        <div className={twJoin('flex w-full items-start justify-between gap-4', !isExpanded && 'items-center')}>
          <MessageIcon src="/icons/blueStar.png" />

          {isExpanded ? (
            <div className="grow self-center">
              <MarkdownWrapper content={content} documentReferences={documentReferences} />
            </div>
          ) : (
            <div className="text-sm text-violet-700">Click arrow icon to show content -&gt;</div>
          )}

          <MessageActions expandedAction={{ isExpanded, setIsExpanded }} copyContent={content} />
        </div>
      </div>
    </Card>
  )
}
