import { addBookmark } from 'api/Bookmark/action/bookmark'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { DefaultModal } from './DefaultModal'
import { toast } from 'react-toastify'

export const AddBookmarkModal = ({ isVisible, onCancel, workspace_id, workspace_type, sub_workspace_id = null }) => {
  const dispatch = useDispatch()
  const [bookmarkName, setBookmarkName] = useState('')

  const onSave = (response) => {
    if (response.status <= 201) {
      toast.success('Bookmark added successfully')
      onCancel()
      return
    }
    toast.error(response.data)
  }

  const handleAddBookmark = async () => {
    if (!bookmarkName.trim()) {
      onSave({ data: 'Please name the bookmark', status: 400 })
      return
    }
    if (!workspace_id) {
      onSave({ data: 'Please start chat before saving bookmark', status: 400 })
      return
    }
    const response = await dispatch(addBookmark(bookmarkName, workspace_id, workspace_type, sub_workspace_id))
    onSave(response)
    setBookmarkName('')
  }

  const handleAddBookmarkCancel = () => {
    onCancel()
    setBookmarkName('')
  }

  return (
    <DefaultModal
      size="s"
      isVisible={isVisible}
      title="Add Bookmark"
      onClose={handleAddBookmarkCancel}
      onSubmit={handleAddBookmark}
    >
      <p className="mb-1 text-xs">Name:</p>
      <input
        placeholder="Enter name"
        className="w-full rounded border py-2 indent-1 text-xs"
        onChange={(e) => setBookmarkName(e.target.value)}
        value={bookmarkName}
      />
    </DefaultModal>
  )
}
