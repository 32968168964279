import { addTask } from 'api/Task/action/action'
import { LoopFormFields } from 'pages/playground/loop/components/LoopFormFields/LoopFormFields'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DefaultModal } from './DefaultModal'

const LOOP_TASK_FORM_ID = 'loop-task-form'

export const AddTaskModal = ({ onSave, onCancel, workspace, showModal }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [inputValue, setInputValue] = useState('')
  const [textFieldValue, setTextFieldValue] = useState('')

  const isLoopTask = pathname === '/playground/loop'

  const handleAddPreset = async (formData) => {
    if (!inputValue.trim()) {
      toast.error('Please fill "Title" field')
      return
    }
    if (!textFieldValue.trim() && !formData) {
      toast.error('Please fill "Instruction" field')
      return
    }

    const body = {
      title: inputValue,
      instructions: textFieldValue || formData?.attention,
      configurations: isLoopTask
        ? {
            goal: formData.goal,
            length: formData.length,
            format: formData.format,
            language: formData.language
          }
        : undefined
    }

    const response = await dispatch(addTask(body.title, body.instructions, body.configurations, workspace))

    if (!response.status || response.status > 201) {
      toast.error(response.data)
      return
    }
    toast.success('Task added successfully')
    onSave()
    setInputValue('')
    setTextFieldValue('')
  }

  const handleAddPresetCancel = () => {
    onCancel()
    setInputValue('')
    setTextFieldValue('')
  }

  return (
    <DefaultModal
      title="Add task"
      isVisible={showModal}
      onSubmit={handleAddPreset}
      onClose={handleAddPresetCancel}
      formId={LOOP_TASK_FORM_ID}
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label>Title</label>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter title"
            className="w-full rounded border py-2 indent-3 text-xs"
          />
        </div>
        <>
          {isLoopTask ? (
            <div className="rounded-md border p-2">
              <LoopFormFields isTaskMode formId={LOOP_TASK_FORM_ID} onSubmit={handleAddPreset} />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <label>Instruction</label>
              <textarea
                value={textFieldValue}
                onChange={(e) => setTextFieldValue(e.target.value)}
                placeholder="Enter instruction"
                className="max-h-40 w-full rounded border py-2 indent-3 text-xs"
                rows="4"
              />
            </div>
          )}
        </>
      </div>
    </DefaultModal>
  )
}
