import { useDispatch } from 'react-redux'

export const useDownloadPDF = () => {
  const dispatch = useDispatch()

  const downloadPDF = async ({ id, setPdfStatus, downloadAction, fileName }) => {
    if (id) {
      dispatch(setPdfStatus('generating'))
      try {
        const response = await dispatch(downloadAction(id))

        if (response.blob) {
          dispatch(setPdfStatus('downloaded'))

          const downloadUrl = window.URL.createObjectURL(response.blob)
          const link = document.createElement('a')
          link.href = downloadUrl
          const date = new Date()
          const formattedDate = `${date.getDate()}_${
            date.getMonth() + 1
          }_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}`
          link.download = `${fileName}_${formattedDate}.pdf`
          link.click()

          window.URL.revokeObjectURL(downloadUrl)
        } else {
          console.error('Failed to download PDF:', response.error || 'Unknown error')
          dispatch(setPdfStatus('error'))
        }
      } catch (error) {
        console.error('Error downloading the PDF:', error)
        dispatch(setPdfStatus('error'))
      }
    }
  }

  return { downloadPDF }
}
