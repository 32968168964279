import * as api from '../index.js'

export const getBookmarks = (urlParams) => async (dispatch) => {
  try {
    const response = await api.getBookmarks(urlParams)
    return response
  } catch (error) {
    return error
  }
}

export const getSingleBookmark = (id) => async (dispatch) => {
  try {
    const response = await api.getSingleBookmark(id)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const deleteBookmark = (body) => async (dispatch) => {
  try {
    const response = await api.deleteBookmark(body)
    return response
  } catch (error) {
    return error
  }
}
export const addBookmark =
  (name, id, type, subId = null) =>
  async (dispatch) => {
    try {
      const data = { name: name, workspace_id: id, workspace_type: type }
      if (subId) data['sub_workspace_id'] = subId
      const response = await api.addBookmark(data)
      if (response.status < 300) return { status: response.status, data: response.data }
    } catch (error) {
      if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
      const e = error.toJSON()
      return { data: e.message, status: e.error_code ?? 400 }
    }
  }
