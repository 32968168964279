import { logout } from 'api/Login/action/action'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'

export const ProfileMenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef(null)
  const buttonRef = useRef(null)

  const { profile } = useSelector(({ login }) => login)

  const userInitial = profile?.first_name?.charAt(0)?.toUpperCase()

  useEffect(() => {
    const handleClickOutside = (event) => {
      //if click on outside menu or button, menu will close it
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleLogout = () => {
    dispatch(logout())
    navigate('/login', { replace: true })
  }

  return (
    <div className="relative inline-block text-left">
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className="flex size-8 items-center justify-center rounded-full bg-gray-800 text-white shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <span className="text-sm font-medium">{userInitial}</span>
      </button>

      <div
        ref={menuRef}
        className={twJoin(
          'absolute right-0 z-50 mt-2 w-60 origin-top-right transform rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-200 ease-in-out focus:outline-none',
          isOpen ? 'scale-100 opacity-100' : 'pointer-events-none scale-95 opacity-0'
        )}
      >
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <div className="px-4 py-2 text-sm text-gray-700">
            <p className="font-medium">{`${profile.first_name} ${profile.last_name}`}</p>
            <p className="text-xs text-gray-500">{profile.email}</p>
          </div>

          <hr className="border-gray-200" />
          {/* 
          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
            👤 Account
          </a>
          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
            ⚙️ Settings
          </a>
          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
            ❓ Help
          </a>

          <hr className="border-gray-200" /> 
          */}

          <button
            className="flex w-full items-center gap-1 px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
            role="menuitem"
            onClick={() => handleLogout()}
          >
            <img src="/icons/logout.svg" className="size-4" alt="logout" />
            <span>Log out</span>
          </button>
        </div>
      </div>
    </div>
  )
}
