import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { Divider } from '@mui/material'
import { getLogs, getSingleLog } from 'api/Log/action/logs.js'
import { getLoop } from 'api/loop/handler.js'
import { getSingleMirror } from 'api/Mirror/action/action.js'
import { getSingleThread, getThreadMessages } from 'api/SmartAssistant/action/action.js'
import { Header } from 'components/shared/Headers/Header.jsx'
import { ASSISTANT, LOOP, MIRROR, WORKSPACE_TYPE } from 'lib/constants/BookMarkConst.jsx'
import moment from 'moment-timezone'
import { ToolbarActions } from 'pages/components/ToolbarActions.jsx'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUrlParams } from 'utils/helpers.js'
import { Pagination } from '../../components/shared/Footers/Pagination.jsx'
import { DetailsSidebar } from '../components/DetailsSidebar.jsx'
import { DetailsSidebarDownload } from '../components/DetailsSidebarDownload.jsx'
import { SortableTh } from '../components/SortableTh.jsx'

export default function Log() {
  const dispatch = useDispatch()

  const [details, setDetails] = useState([])
  const [logDetails, setLogDetails] = useState([])
  const [mirrorDetails, setMirrorDetails] = useState(null)
  const [threadDetails, setThreadDetails] = useState(null)
  const [threadMessages, setThreadMessages] = useState([])
  const [hasDocuments, setHasDocuments] = useState(true)
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [selectedDetails, setSelectedDetails] = useState(null)
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' })
  const [loopDetails, setLoopDetails] = useState(null)

  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(null)
  const [limit, setLimit] = useState(null)
  const [offset, setOffset] = useState(0)

  const fetchLogs = async () => {
    setLoading(true)
    const response = await dispatch(getLogs({ offset }))
    if (response && Array.isArray(response.data.results) && !response.error) {
      setDetails((details) => [...response.data.results])
      if (response.data.next !== null) {
        let params = getUrlParams(response.data.next)
        setCount(response.data.count)
        setLimit(parseInt(params.limit))
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchLogs()
  }, [offset])

  const getLogDetails = async (id) => {
    const response = await dispatch(getSingleLog(id))
    if (!response.status || response.status !== 200) return
    setLogDetails(response.data)
    if (response.data.workspace_type === MIRROR) {
      const mirrordetail = await dispatch(getSingleMirror(response.data.data.mirror))
      if (!response.status || response.status !== 200) return
      setMirrorDetails(mirrordetail.data)
    }
    if (response.data.workspace_type === LOOP) {
      const loopdetails = await dispatch(getLoop(response.data.data.loop))
      if (!response.status || response.status !== 200) return
      setLoopDetails(loopdetails.data)
    }
    if (response.data.workspace_type === ASSISTANT) {
      const threaddetails = await dispatch(getSingleThread(response.data.data.thread))
      if (!threaddetails.status || threaddetails.status !== 200) return

      if (threaddetails.data.documents.length === 0) {
        setHasDocuments(false)
      }
      setThreadDetails(threaddetails.data)

      const threadmessages = await dispatch(getThreadMessages(response.data.data.thread))
      if (!threadmessages.status || threadmessages.status !== 200) return

      setThreadMessages(threadmessages.data.messages)
    }
  }

  const openSidebar = async (index) => {
    setSelectedDetails(sortedDetails[index])
    await getLogDetails(sortedDetails[index].id)
    setSidebarVisible(true)
  }

  const closeSidebar = () => {
    setSidebarVisible(false)
    setSelectedDetails(null)
  }

  const sortedDetails = React.useMemo(() => {
    let sortableDetails = [...details]
    if (sortConfig.key) {
      sortableDetails.sort((a, b) => {
        if (sortConfig.key === 'updated_at') {
          const dateA = new Date(a[sortConfig.key])
          const dateB = new Date(b[sortConfig.key])

          if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1
          if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1
          return 0
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
          return 0
        }
      })
    }
    return sortableDetails
  }, [details, sortConfig])

  const getIdByWorkspace = () => {
    switch (logDetails?.workspace_type) {
      case ASSISTANT:
        return threadDetails?.id
      case LOOP:
        return loopDetails?.id
      case MIRROR:
        return mirrorDetails?.id
      default:
        break
    }
  }

  return (
    <div>
      <Header heading="Logs" icon="/icons/clock.png" />
      <div className="w-full ">
        <div>
          <table className="w-full overflow-x-hidden rounded-xl border md:w-full lg:w-full">
            <thead className="bg-[#0000000D] text-left  text-xs text-[#00000066] ">
              <tr>
                <th scope="col" className="big">
                  S.NO
                </th>

                <SortableTh
                  title="Date/Time"
                  sortKey="updated_at"
                  // onSort={onSort}
                  sortConfig={sortConfig}
                  setSortConfig={setSortConfig}
                />
                <SortableTh
                  title="From"
                  sortKey="workspace_type"
                  // onSort={onSort}
                  sortConfig={sortConfig}
                  setSortConfig={setSortConfig}
                />
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedDetails.map((item, index) => (
                <tr key={index} className={`border-b bg-white  text-[#1C1C1C]  `}>
                  <th scope="row" className="small gap-1 whitespace-nowrap">
                    {index < 9 ? (
                      <>
                        <p>0{index + 1}</p>
                      </>
                    ) : (
                      <>
                        <p>{index + 1}</p>
                      </>
                    )}
                  </th>
                  <td className="small gap-1">{moment(item.updated_at).tz(moment.tz.guess()).format('LLL')}</td>
                  <th className="small gap-1">{WORKSPACE_TYPE[item.workspace_type]}</th>
                  <td className="text-center">
                    <button onClick={() => openSidebar(index)} className="hover:opacity-50">
                      <img className="size-5" src="/icons/eye.png" alt="add" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-center pt-2'>
          <Pagination count={count} limit={limit} offset={offset} setOffset={setOffset} loading={loading} />
        </div>
      </div>

      {sidebarVisible && (
        <ClickAwayListener
          onClickAway={() => {
            setSidebarVisible(false)
          }}
        >
          <div className="fixed right-0 top-0 z-50 h-full w-1/3 overflow-auto bg-white shadow-lg">
            <div className="ml-3 mr-3 ">
              <div>
                {logDetails && (
                  <div className="">
                    <div className="mt-4 flex justify-between">
                      <h1 className="small mb-2 font-semibold">{logDetails.name}</h1>
                      <button onClick={closeSidebar} className="mb-4 flex items-center text-xs">
                        <div className="flex items-center gap-1 opacity-80">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons/cross-circle.png`}
                            className="size-4"
                            alt="close"
                          />
                          <span>Close</span>
                        </div>
                      </button>
                    </div>

                    <div className="mb-2 flex items-end justify-between">
                      <div className="flex flex-col gap-1 text-xs">
                        <p>
                          From: <span className="font-semibold">{WORKSPACE_TYPE[logDetails.workspace_type]}</span>
                        </p>

                        <p>
                          Date/Time:{' '}
                          <span className="font-semibold">
                            {moment(selectedDetails.created_at).tz(moment.tz.guess()).format('LLL')}
                          </span>
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <ToolbarActions
                          defaultWorkspace={logDetails?.workspace_type}
                          defaultId={getIdByWorkspace()}
                          hasBookmarksButton={false}
                        />
                      </div>
                    </div>

                    <Divider />

                    {hasDocuments && <p className="big mt-4 font-semibold">Source</p>}

                    {logDetails.workspace_type === MIRROR && (
                      <div class="mt-2 flex flex-wrap items-center justify-start gap-2">
                        <DetailsSidebarDownload
                          name={mirrorDetails?.document_a?.title}
                          id={mirrorDetails?.document_a?.id}
                          type={mirrorDetails?.document_a?.mime_type}
                        />
                        <DetailsSidebarDownload
                          name={mirrorDetails?.document_b?.title}
                          id={mirrorDetails?.document_b?.id}
                          type={mirrorDetails?.document_b?.mime_type}
                        />
                      </div>
                    )}
                    {logDetails.workspace_type === LOOP && (
                      <div class="mt-2 flex flex-wrap items-center justify-start gap-2">
                        <DetailsSidebarDownload
                          name={loopDetails?.document?.title}
                          id={loopDetails?.document?.id}
                          type={loopDetails?.document?.mime_type}
                        />
                      </div>
                    )}
                    {logDetails.workspace_type === ASSISTANT && (
                      <div className="mt-2 flex flex-wrap items-center justify-start gap-2">
                        {threadDetails?.documents.map(({ id, title, mime_type }) => (
                          <DetailsSidebarDownload key={id} id={id} name={title} type={mime_type} />
                        ))}
                      </div>
                    )}

                    <div className=" flex flex-col gap-4">
                      <strong className="small mt-3">Output</strong>
                      {logDetails.workspace_type === MIRROR &&
                        mirrorDetails?.questions.map((item, index) => (
                          <DetailsSidebar key={index} prompt={item.data} response={item.response} />
                        ))}
                      {logDetails.workspace_type === 'ASSISTANT' &&
                        threadMessages.map((item) => (
                          <DetailsSidebar
                            key={item.id}
                            prompt={item.role === 'user' ? item.data : null}
                            response={item.role === 'assistant' ? item.data : null}
                          />
                        ))}
                      {logDetails.workspace_type === 'LOOP' && (
                        <DetailsSidebar
                          key={loopDetails.id}
                          prompt={loopDetails.instructions}
                          response={loopDetails.result}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}
