import { ExpandableInput } from './components/ExpandableInput'

export const FooterActions = ({ onSubmit }) => {
  return (
    <div className="flex w-full items-center justify-center pt-4">
      <div className="no-border w-4/5">
        <div className="mx-3 pb-2 ">
          <ExpandableInput onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}
