import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpenSidebar: false
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setIsOpenSidebar: (state, action) => {
      state.isOpenSidebar = action.payload
    }
  }
})

export const { setIsOpenSidebar } = navigationSlice.actions
export default navigationSlice.reducer
