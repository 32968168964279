import { refresh } from 'api/Login/action/action'
import { setIsAuthorized } from 'app/features/loginSlice'
import { jwtDecode } from 'jwt-decode'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({ redirectAuthenticated = false }) => {
  const dispatch = useDispatch()
  const { isAuthorized } = useSelector(({ login }) => login)

  useEffect(() => {
    auth().catch(() => dispatch(setIsAuthorized(false)))
  }, [])

  const refreshToken = async () => {
    const refreshToken = JSON.parse(localStorage.getItem('refresh_token'))
    try {
      const response = await dispatch(refresh({ refresh_token: refreshToken }))
      if (response.status < 300) {
        localStorage.setItem('access_token', JSON.stringify(response.data.access_token))
        dispatch(setIsAuthorized(true))
      } else {
        dispatch(setIsAuthorized(false))
      }
    } catch (error) {
      dispatch(setIsAuthorized(false))
    }
  }

  const auth = async () => {
    const token = JSON.parse(localStorage.getItem('access_token'))
    const refresh = JSON.parse(localStorage.getItem('refresh_token'))
    if (!token && !refresh) {
      dispatch(setIsAuthorized(false))
      return
    }
    if (refresh) {
      await refreshToken()
    }
    const decoded = jwtDecode(token)
    const now = Date.now() / 1000

    if (decoded.exp < now + 200) {
      localStorage.removeItem('access_token')
      await refreshToken()
    } else {
      dispatch(setIsAuthorized(true))
    }
  }

  if (isAuthorized == null) {
    return <div></div>
  }

  if (isAuthorized && redirectAuthenticated) {
    return <Navigate to="/playground" replace />
  }

  if (!isAuthorized && !redirectAuthenticated) {
    return <Navigate to="/login" replace />
  }

  return <Outlet />
}

export default ProtectedRoute
