import { downloadDocument } from 'api/Document/action/document'
import { useDispatch } from 'react-redux'

export const useDownloadFile = () => {
  const dispatch = useDispatch()

  const downloadFile = async ({ name, type, id }) => {
    if (!id) return console.error('Download id not specified')
    try {
      const data = await dispatch(downloadDocument({ id }))
      const blob = new Blob([data.data], { type: type })

      if (blob) {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${name}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url) // Limpia la URL después de descargar
      }
    } catch (error) {
      console.error('Error downloading document:', error)
    }
  }

  return { downloadFile }
}
