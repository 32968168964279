import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { twJoin, twMerge } from 'tailwind-merge'
import { SidebarLinkDrop } from './SidebarLinkDrop'
import { useSelector } from 'react-redux'

const linkClass = 'flex items-center gap-2 font-light hover:text-blue-700 hover:no-underline rounded-lg'

export const SidebarLink = ({ link }) => {
  const { pathname } = useLocation()
  const { isOpenSidebar } = useSelector(({ navigation }) => navigation)

  const [drop, setDrop] = useState(link.drop)
  const isActive = pathname.startsWith(link.path)

  const handleDropdownClick = (e) => {
    e.stopPropagation() // Stop the event from bubbling up to higher components
    e.preventDefault() // Prevent default link behavior
    setDrop(!drop)
  }

  return (
    <>
      <Link
        to={link.disabled ? '#' : link.path}
        className={twMerge(
          isActive && !link.disabled ? 'rounded-lg bg-blue-100 text-blue-800 active:bg-blue-100' : 'text-black',
          linkClass,
          link.disabled ? 'bg-hover:transparent cursor-not-allowed' : 'hover:bg-blue-100',
          'py-2'
        )}
      >
        <div className="flex shrink-0 items-center">
          <span className="mx-2 text-xs text-gray-600">
            <img
              className={twJoin('h-6 w-6', link.disabled ? 'cursor-not-allowed opacity-50' : '')}
              src={link.icon}
              alt={`${link.label} logo`}
            />
          </span>

          {isOpenSidebar && (
            <span
              className={twJoin('text-[14px] text-[#1C1C1C]', link.disabled ? 'cursor-not-allowed opacity-50' : '')}
            >
              {link.label}
            </span>
          )}
        </div>

        {link.drop && (
          <div>
            {drop ? (
              <img
                onClick={handleDropdownClick}
                className="h-4 w-4 text-sm hover:cursor-pointer"
                src="/icons/up.png"
                alt="down arrow"
              />
            ) : (
              <img
                onClick={handleDropdownClick}
                className="h-4 w-4 text-sm hover:cursor-pointer"
                src="/icons/down.png"
                alt="up arrow"
              />
            )}
          </div>
        )}
      </Link>
      {drop && link.dropable && (
        <div className="pl-3">
          {link.dropable.map((sublink) => (
            <SidebarLinkDrop key={sublink.key} link={sublink} />
          ))}
        </div>
      )}
    </>
  )
}
