import { setShowMessageMirror } from 'app/features/fileSlice'
import {
  addTask,
  removeTask,
  setIsGenerated,
  setIsListVisible,
  setIsLoading,
  setPdfStatus,
  setStop,
  setTasks
} from 'app/features/mirrorSlice'
import { toggleOffPresetSelection } from 'app/features/mirrorTasksSlice'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { twJoin, twMerge } from 'tailwind-merge'

export const ExpandableInput = ({ onSubmit }) => {
  const dispatch = useDispatch()
  const { tasks, isListVisible, isLoading } = useSelector((state) => state.mirror)
  const { qntyFiles } = useSelector(({ files }) => files)
  const [inputValue, setInputValue] = useState('')
  const lastTaskRef = useRef(null)
  const inputValueRef = useRef(null)

  const isDisabledButton = tasks.length === 0

  useEffect(() => {
    if (isListVisible && tasks.length > 0) {
      lastTaskRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (tasks.length === 0) {
      dispatch(setIsListVisible(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, isListVisible, lastTaskRef])

  const handleAddTask = () => {
    const inputValue = inputValueRef.current?.value && inputValueRef.current.value.trim()
    if (inputValue !== '') {
      dispatch(addTask({ data: inputValue, id: 'no-id' })) // Dispatch action to add task
      inputValueRef.current.value = '' // Clear input
      dispatch(setIsListVisible(true)) // Set list visible
    }
  }

  const handleRemoveTask = (index, id) => {
    if (id == 'no-id') {
      dispatch(removeTask(index))
      return
    }
    dispatch(removeTask(index))
    dispatch(toggleOffPresetSelection(id))
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddTask()
      setInputValue('')
    }
  }

  const handleToggleList = () => {
    if (tasks.length > 0) {
      dispatch(setIsListVisible(!isListVisible)) // Dispatch action to toggle list visibility
    }
  }

  const handleStop = () => {
    dispatch(setStop(true))
    dispatch(setIsLoading(false))
  }

  const handleGenerate = async () => {
    if (tasks.length) {
      if (qntyFiles === 2) {
        dispatch(setIsLoading(true))
        dispatch(setIsGenerated(false))
        dispatch(setStop(false))
        await onSubmit()
        dispatch(setPdfStatus(''))
        dispatch(setShowMessageMirror(false))
        dispatch(setIsLoading(false))
        dispatch(setIsGenerated(true))
        dispatch(setTasks([]))
        dispatch(setIsListVisible(false))
        setTimeout(() => {
          setInputValue('')
          dispatch(setIsGenerated(true))
          dispatch(setIsLoading(false))
        }, 3000)
      } else {
        dispatch(setShowMessageMirror(true))
      }
    }
  }

  return (
    <>
      {isListVisible && (
        <div className="mb-2 h-20 overflow-y-auto">
          {tasks.map((task, index) => (
            <div key={index} ref={index === tasks.length - 1 ? lastTaskRef : null}>
              <div className="w-full overflow-auto">
                <div className="m-1 mx-2 rounded-md bg-gray-50">
                  <div className="flex justify-between">
                    <div className="mr-2 overflow-hidden p-1">
                      <p className="small wrapped-text">{task.data}</p>
                    </div>

                    <button className="mr-2 rounded-lg bg-gray-50">
                      <img
                        className="size-5"
                        src="/icons/tag-cross.png"
                        alt="delete"
                        onClick={() => handleRemoveTask(index, task.id)}
                        style={{ minWidth: 'auto' }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div
        className={`flex  w-full items-center justify-center rounded-lg pl-3 shadow-md ${
          isListVisible ? 'no-border shadow-none' : 'border'
        }`}
      >
        <input
          ref={inputValueRef}
          className="small active:no-border my-2 ml-1 mr-2 w-full rounded-md py-2 pr-2 focus:outline-none"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="How can I help you?"
        />
        <button
          className={twJoin('m-1 mr-2 h-4 w-4', isDisabledButton && 'cursor-not-allowed')}
          onClick={handleToggleList}
          disabled={isDisabledButton}
        >
          <img src="/icons/full.png" className="bg-white" alt="toggle list visibility" />
        </button>

        <div className="flex items-center justify-between">
          <button onClick={isLoading ? handleStop : handleGenerate}>
            <div
              className={twMerge(
                'align-center my-2 mr-2 flex justify-center rounded-md px-5 py-2',
                isDisabledButton
                  ? 'cursor-not-allowed bg-gray-100 text-gray-400'
                  : 'bg-gradient-to-r from-purple-500 to-purple-700 text-white'
              )}
            >
              <img
                className="mr-1 h-4 w-4"
                src={`${isDisabledButton ? '/icons/grayStar' : '/icons/whiteStar'}.png`}
                alt="Generate"
              />
              <p className="big ml-1">{isLoading ? 'Stop' : 'Generate'}</p>
            </div>
          </button>
        </div>
      </div>
    </>
  )
}
