import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { twJoin, twMerge } from 'tailwind-merge'

export const Textarea = ({
  className,
  defaultValue = '',
  label = '',
  iconSrc = '',
  iconInfoTooltip = false,
  register = {}
}) => {
  return (
    <div className={twJoin(!!label && 'flex flex-col gap-2')}>
      {!!label && (
        <label className="flex gap-1">
          <span>{label}</span>
          <ReactTooltip tooltipContent={iconInfoTooltip} place="top" enabled={!!iconInfoTooltip}>
            {!!iconSrc && <img src={iconSrc} alt={iconInfoTooltip} />}
          </ReactTooltip>
        </label>
      )}

      <textarea
        className={twMerge('w-full rounded-md bg-gray-100 p-3', className)}
        defaultValue={defaultValue}
        {...register}
      />
    </div>
  )
}
