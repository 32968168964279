import './styles.css'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import styles from './Markdown.module.css'

const CITATION_REGEX = /\[\d+\]/g

export const MarkdownWrapper = ({ content = '', documentReferences = [] }) => {
  if (documentReferences.length > 0) {
    const citations = content.match(CITATION_REGEX)
    const partsWithNoCitations = content.split(CITATION_REGEX)

    const parsedText = partsWithNoCitations.map((part, index) => {
      let text = `<span key={part-${index}}>${part}</span>`

      let citation =
        citations &&
        citations[index] &&
        `<span className="tooltip-container"> 
              <span className="tooltip-trigger">${citations[index]}</span>
              <span className="tooltip">${documentReferences[index]?.document}</span>
          </span>`

      return `${text} ${citation != null ? citation : ''}`
    })

    return <ReactMarkdown children={parsedText.join('')} className={styles.markdown} rehypePlugins={[rehypeRaw]} />
  } else {
    return (
      <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
    )
  }
}
