import Mirror from 'pages/playground/mirror/Mirror'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from './components/shared/Login/Login'
import Layout from './layouts/Layout'
import Bookmark from './pages/Bookmark/Bookmark'
import Files from './pages/Files/Files'
import Logs from './pages/Logs/Logs'
import Assistant from './pages/playground/assistant/Assistant'
import Loop from './pages/playground/loop/Loop'
import ProtectedRoute from './protectedRoutes/protectedRoutes'

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<ProtectedRoute redirectAuthenticated={true} />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Navigate to="/playground/assistant" replace />} />
          <Route path="/playground" element={<Navigate to="/playground/assistant" replace />} />
          <Route path="/playground/assistant" element={<Assistant />} />
          <Route path="/playground/loop" element={<Loop />} />
          <Route path="/playground/mirror" element={<Mirror />} />
          <Route path="/bookmarks" element={<Bookmark />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/files" element={<Files />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
)
