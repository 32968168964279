import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/counterSlice'
import fileReducer from './features/fileSlice'
import loopReducer from './features/loopSlice'
import loopTasksReducer from './features/loopTasksSlice'
import mirrorReducer from './features/mirrorSlice'
import mirrorTasksReducer from './features/mirrorTasksSlice'
import navigationReducer from './features/navigationSlice'
import smartChatReducer from './features/smartChatSlice'
import smartChatTasksReducer from './features/smartChatTasksSlice'
import loginReducer from './features/loginSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    navigation: navigationReducer,
    files: fileReducer,
    mirror: mirrorReducer,
    mirrorTasks: mirrorTasksReducer,
    smartChat: smartChatReducer,
    smartChatTasks: smartChatTasksReducer,
    loop: loopReducer,
    loopTasks: loopTasksReducer,
    login: loginReducer
  }
})
