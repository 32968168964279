import { IAMessageCard, UserMessageCard } from 'components/shared/Card'
import { DarkSpinner, Spinner } from 'components/shared/Loaders'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
export const Messages = forwardRef((_, ref) => {
  const { data, isLoading } = useSelector(({ smartChat }) => smartChat)

  return (
    <>
      {isLoading && data.length === 0 && <DarkSpinner />}

      <div ref={ref} className="grow-0 overflow-y-auto">
        {data.length !== 0 && (
          <div className="m-5 flex flex-col gap-4">
            {data.map((item, index) => (
              <div key={index} className="flex flex-col gap-4">
                <UserMessageCard content={item.question} />
                <div className="w-[99%] self-end">
                  <IAMessageCard content={item.answer.response} documentReferences={item.answer.documentReferences}/>
                </div>
              </div>
            ))}

            {isLoading && data.length !== 0 && (
              <div className="mx-auto mb-2 w-10">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
})
