import * as api from './index'
import { mapper } from './utils/mapper'
export const postLoop = (data) => async () => {
  try {
    const response = await api.postLoop(mapper(data))

    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const postProcessLoop = (id) => async () => {
  try {
    const response = await api.postProcessLoop(id)

    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const getLoop = (id) => async () => {
  try {
    const response = await api.getLoop(id)

    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const downloadPDF = (threadId) => async (dispatch) => {
  try {
    const response = await api.downloadPDF(threadId, { responseType: 'blob' })

    if (response.status < 300) {
      return { status: response.status, blob: response.data }
    } else {
      return { status: response.status, error: 'Failed to download PDF' }
    }
  } catch (error) {
    if (error.response) {
      return { error: error.response.data.message ?? error.message, status: error.response.status ?? 400 }
    } else {
      const e = error.toJSON ? error.toJSON() : { message: error.message, error_code: 400 }
      return { error: e.message, status: e.error_code }
    }
  }
}

export const getLoogConfig = () => async () => {
  try {
    const response = await api.getLoogConfig()

    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}