// websocketService.js

class WebSocketService {
  constructor() {
    this.socket = null;
    this.listeners = {};
    this.isConnected = false;
  }

  // Initialize the WebSocket connection
  connect(url) {
    return new Promise((resolve, reject) => {
      if (this.isConnected) {
        resolve();
        return;
      }

      this.socket = new WebSocket(url);

      this.socket.onopen = () => {
        console.log('WebSocket connected.');
        this.isConnected = true;
        resolve();
      };

      this.socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (this.listeners[data.action]) {
            this.listeners[data.action](data);
          } else {
            console.warn('Unhandled action:', data.action);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
      };

      this.socket.onclose = (event) => {
        console.log('WebSocket closed:', event.code, event.reason);
        this.isConnected = false;
        this.socket = null;
      };
    });
  }

  // Send messages to the server
  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not connected.');
    }
  }

  // Listen for specific actions
  on(action, callback) {
    this.listeners[action] = callback;
  }

  // Remove specific listeners
  off(action, callback) {
    if (this.listeners[action] === callback) {
      delete this.listeners[action];
    }
  }

  // Clean up listeners and close the connection
  disconnect() {
    this.listeners = {};
    if (this.socket) {
      this.socket.close();
      this.socket = null;
      this.isConnected = false;
    }
  }
}

export default new WebSocketService();
