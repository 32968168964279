import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  pdfStatus: 'ready',
  assistantIdRedux: null,
  threadId: null,
  isLoading: false,
  message: ''
}

const smartChatSlice = createSlice({
  name: 'smartChat',
  initialState,
  reducers: {
    setPdfStatus(state, action) {
      state.pdfStatus = action.payload
    },
    setData(state, action) {
      state.data = action.payload
    },
    setThreadId(state, action) {
      state.threadId = action.payload
    },
    addData(state, action) {
      state.data.push(action.payload)
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setMessage(state, action) {
      state.message = action.payload
    },
    resetsmartchatState: () => initialState
  }
})

export const { setPdfStatus, setData, resetsmartchatState, addData, setThreadId, setIsLoading, setMessage } =
  smartChatSlice.actions

export default smartChatSlice.reducer
