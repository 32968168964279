import { TruncatedText } from 'components/shared/Tooltip'
import { useDownloadFile } from 'hooks/useDownloadFile'

export const DetailsSidebarDownload = ({ id, name, type }) => {
  const { downloadFile } = useDownloadFile()

  return (
    <div className="flex items-center justify-between gap-2 rounded border border-gray-300 px-3 py-2 text-xs">
      <TruncatedText content={<span className="cursor-default">{name}</span>} />
      <button className="right-text cursor-pointer text-[#3244E8]" onClick={() => downloadFile({ name, type, id })}>
        Download
      </button>
    </div>
  )
}
