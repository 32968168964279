import { Card } from 'components/shared/Card/Card'
import { useState } from 'react'
import { twJoin } from 'tailwind-merge'
import { MessageActions } from './components/MessageActions'
import { MessageIcon } from './components/MessageIcon'

export const UserMessageCard = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <Card className="border-0 bg-gray-100 p-3 text-sm">
      <div className="flex">
        <div className={twJoin('flex w-full items-start justify-between gap-4', !isExpanded && 'items-center')}>
          <MessageIcon src="/icons/user.png" />

          {isExpanded ? (
            <div className="grow self-center">{content}</div>
          ) : (
            <div className="text-violet-700">Click arrow icon to show content -&gt;</div>
          )}

          <MessageActions expandedAction={{ isExpanded, setIsExpanded }} copyContent={content} />
        </div>
      </div>
    </Card>
  )
}
